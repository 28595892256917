'use strict'

// sass
import '../sass/main.sass'

// img
import '../img/favicon.ico'

// js
import './contact'
import './project'
