(() => {
  const emails = document.querySelectorAll('.email')
  emails.forEach((email) => {
    const addr = email.dataset.name + '@' + email.dataset.domain
    email.textContent = addr
    email.setAttribute('href', `mailto:${addr}`)
    email.classList.remove('email')
    email.removeAttribute('data-name')
    email.removeAttribute('data-domain')
  })

  const phones = document.querySelectorAll('.phone')
  phones.forEach((phone) => {
    const num = phone.dataset.num.split('').reverse().join('')
    phone.textContent = num
    phone.setAttribute('href', 'tel:' + num.replaceAll(' ', ''))
    phone.removeAttribute('data-num')
  })
})()