(() => {
  const project = document.querySelector('#project')
  const images = document.querySelectorAll('[data-project-image]')

  images.forEach((el) => {
    el.addEventListener('click', () => {
      project.classList.toggle('image-mode')
    })
  })
})()
